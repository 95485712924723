import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"

import Terms from "../components/Terms"

const TermsPage = () => {
  return (
    <Layout>
      <SEO title="Terms" />
      <Terms />
    </Layout>
  )
}

export default TermsPage
