import React from "react"
import styled from "styled-components"

import media from "../../styles/media"

const Container = styled.section`
  padding: var(--gutter-l);
  h1 {
    font-size: 3rem;
    line-height: 1;
    transform: translateY(30%);
    position: relative;
    z-index: 2;
    margin: var(--gutter-l) 0;
  }
  p {
    padding: var(--gutter-s) 0;
  }
  ${media.tablet`
    padding: var(--gutter-m) 0 var(--gutter-l) var(--gutter-l);
    h1 {
      font-size: 5.5rem;
    }
  `}
`

const Terms = () => {
  return (
    <Container name="terms">
      <h1 className="font-black">terms</h1>
      <p>Under 18&apos;s may not get tattooed even with parental consent</p>
      <p>Pregnant or breastfeeding woman may not get tattooed</p>
      <p>No persons under the influence of drugs or alcohol may get tattooed</p>
      <p>Please note tickets are non-refundable</p>
      <p>Right of admission reserved</p>
      <p>Event ticket does not include a tattoo</p>
      <p>Under 12&#39;s get in for free</p>
    </Container>
  )
}

export default Terms
